<template>
  <div>
    <van-nav-bar :fixed="true" class="nav_bar_view" left-arrow>
      <template #left>
        <img
          @click="returnPage"
          style="width: 18px; height: 18px"
          src="../../assets/black_return_view_bg.png"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">收货地址</span>
      </template>
      <template
        #right
        style="display: flex; flex-direction: row; align-items: center"
      >
        <span style="font-size: 14px" @click="toPage(1)">新增地址</span>
      </template>
    </van-nav-bar>

    <div class="address_list_view">
      <van-radio-group
        class="group_view"
        v-model="radio"
        checked-color="#00B05A"
      >
        <div
          class="group_class_view"
          v-for="(item, i) in addressList"
          :key="i"
          @click="onchanges(item)"
        >
          <van-radio :name="item.id" style="flex: 1">
            <div class="user_info_view" @click="isCheck">
              <div class="top_user_info_view">
                <span>{{ item.contactPerson }}</span>
                <span style="margin-left: 20px">{{ item.phone }}</span>
                <!-- <div class="mr_view">
                  {{
                    item.isDefault === "DISABLE"
                      ? "禁用"
                      : item.isDefault === "ENABLE"
                      ? "启用"
                      : ""
                  }}
                </div> -->
              </div>
              <span class="address_tv_view">{{ item.address }}</span>
            </div>
          </van-radio>

          <div class="two_button_view">
            <img
              src="../../assets/delete_bg.png"
              @click="handelDel(item.id)"
              alt=""
            />
            <img
              @click="toPage(2, item)"
              src="../../assets/edit_bg.png"
              alt=""
            />
          </div>
        </div>

        <!-- <div class="group_class_view">
          <van-radio :name="!isDefault ? radio : '2'"></van-radio>
          <div class="user_info_view" @click="isCheck">
            <div class="top_user_info_view">
              <span>Celine</span>
              <span>13945678912</span>
              <div class="mr_view">默认</div>
            </div>
            <span class="address_tv_view"
              >31 huilongguan street, changping district, Beijing, China</span
            >
          </div>
          <div class="button_view">
            <img src="../../assets/delete_bg.png" alt="" />
            <img @click="toPage(2)" src="../../assets/edit_bg.png" alt="" />
          </div>
        </div> -->
      </van-radio-group>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

import {
  imgApp,
  app2,
  receiptGetList,
  receiptDelete,
} from "@/service/api/index.js";
import LoginVue from "../login/Login.vue";
export default {
  data() {
    return {
      radio: 1,
      isDefault: true,
      activeIcon: "../../assets/address_check_bg.png",
      inactiveIcon: "../../assets/address_no_check_bg.png",
      addressList: [],
      fromPath: "",
    };
  },
  mounted() {
    this.receiptGetList();
  },
  // beforeRouteEnter(to, from, next) {
  //   this.fromPath = from.path;
  //   next();
  // },
  methods: {
    receiptGetList() {
      receiptGetList().then((res) => {
        if (res.status == 200) {
          this.addressList = res.data;
          const list = this.addressList.filter(
            (el) => el.isDefault === "ENABLE"
          );
          if (list.length) {
            this.radio = list[0].id;
          }
        }
      });
    },
    handelDel(id) {
      receiptDelete({ id }).then((res) => {
        if (res.status == 200) {
          Toast("删除成功");
          this.receiptGetList();
        }
      });
    },
    onchanges(item) {
      if (this.$route.query.type === "1") {
        const { contactPerson, address, houseNumber, phone, id } = item;
        this.$router.replace({
          path: "/confirmOrder",
          query: Object.assign(this.$route.query, {
            address,
            contactPerson,
            houseNumber,
            phone,
            ressId: id,
          }),
        });
      }else if(this.$route.query.type === "3"){
        const { contactPerson, address, houseNumber, phone, id } = item;
        this.$router.replace({
          path: "/againOrder",
          query: Object.assign(this.$route.query, {
            address,
            contactPerson,
            houseNumber,
            phone,
            ressId: id,
          }),
        });
      } else {
      }
    },
    isCheck() {
      if (this.isDefault) {
        this.isDefault = false;
      } else {
        this.isDefault = true;
      }
    },
    toPage(e, item) {
      console.log(item)
      if (e === 1) {
        this.$router.push("./newAddress");
      } else {
        this.$router.push({
          path: "editAddress",
          query: {
            addr: JSON.stringify(item),
          },
        });
      }
    },
    returnPage() {
      if (this.$route.query.type === "1") {
        this.$router.replace({
          // this.$router.push({
          path: "/confirmOrder",
          query: Object.assign(this.$route.query),
        });
      }else if(this.$route.query.type === "3"){
        this.$router.replace({
          // this.$router.push({
          path: "/againOrder",
          query: Object.assign(this.$route.query),
        });
      } else {
        this.$router.push("/user");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .address_list_view {
    max-width: 540px;
  }
}
.nav_bar_view {
  width: 100%;
  height: 44px;
  background: white;
  color: #00b05a;
}
.address_list_view {
  max-width: 540px;
  width: 100%;
  margin-top: 45px;
  display: flex;
  background: white;
  flex-direction: column;
  align-items: center;
  .group_view {
    width: 100%;
    height: 1000px;
    .group_class_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 12px;
      height: 90px;
      width: 93%;
      border-bottom: 1px solid #f2f2f2;
      background: white;
      .img-icon {
        height: 20px;
        z-index: 10;
        margin-left: 12px;
      }
    }
  }

  .user_info_view {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 10px;
    .top_user_info_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 13px;
        color: #3f3f3f;
      }
      div {
        width: 24px;
        height: 14px;
        background: #df3436;
        color: white;
        text-align: center;
        margin-left: 13px;
        line-height: 14px;
        padding: 2px;
        font-size: 10px;
      }
    }
    .address_tv_view {
      font-size: 12px;
      color: #999999;
      margin-top: 5px;
      margin-right: 38px;
    }
  }
  .two_button_view {
    display: flex;
    flex-direction: column;
    width: 50px;
    img {
      width: 24px;
      height: 24px;
    }
    img:last-of-type {
      margin-top: 20px;
    }
  }
}
</style>
